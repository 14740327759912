@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import 'stylesheets/common/index';

*, ::before, ::after {
  box-sizing: border-box;
}
code {
  font-family: 'Roboto', sans-serif;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  img {
    max-width: 100%;
  }
  
  .Mui-focused {
    color: $color-primary !important;
  }
  
  
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .MuiPaper-root {
    &::-webkit-scrollbar {
      height: 4px;              /* height of horizontal scrollbar ← You're missing this */
      width: 4px;               /* width of vertical scrollbar */
    }
  }
}



.breadcrumb {
  @include liststyle(5px 0 0);
  display: flex;
  li {
    @include font(12px, $base-text-color);
    &:not(:last-child) {
      &:after {
        content: '/';
        margin: 0 5px;
      }
    }
    &:last-child {
      color: $color-primary;
      font-weight: 500;
    }
  }
}
@import 'stylesheets/common/index';

.salesPage {
    padding: 30px 15px;
    min-height: calc(100vh - 72px);
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include md {
            flex-wrap: wrap;
        }
        .pageTitle {
            @include md {
                flex: 0 0 50%;
                max-width: 50%;
                order: 2
            }
            h3 {
                @include roboto(500);
                @include font(16px, $base-heading-color);
            }

        }
        .customBtn {
            @include md {
                flex: 0 0 50%;
                max-width: 50%;
                order: 3;
                display: flex;
                justify-content: flex-end;
            }
        }
        .searchBox {
            margin-left: auto;
            min-width: 250px;
            margin-bottom: 0;
            @include md {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 10px;
            }
        }
        .searchBoxCity {
            min-width: 200px;
            margin-bottom: 0;
            padding-left: 15px;
            margin-right: 15px;
            @include md {
                padding-left: 0;
                flex: 0 0 100%;
                margin-bottom: 20px;
            }
        }
    }
    &__content {
        padding-left: 0;
        .MuiTabs-flexContainer {
            background-color: #f7f7f7;
            @include border-radius(5px);
            .MuiTab-textColorPrimary {
                color: $color-black;
                &.Mui-selected {
                    color: $color-primary;
                }
            }
        }
        .MuiTabs-indicator {
            background-color: $color-primary;
        }

        .addPopup {
            position: fixed;
            bottom: 20px;
            right: 20px;
            color: $color-white;
            background-color: $color-primary !important;
        }

        // Person Tab
        .person {
            &__title {
                @include roboto(400);
                margin-bottom: 10px;
                @include font(18px, $base-heading-color);
            }
            &__info {
                width: 100%;
                padding: 5px 15px;
                margin-bottom: 15px;
                @include border-radius(10px);
                background-color: rgba($color-primary, 0.7);
                ul {
                    @include liststyle(0);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    li {
                        line-height: 36px;
                        @include font(14px, $color-white);
                        b {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

    }
}
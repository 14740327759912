@import 'stylesheets/common/index';

body {

    .formGroup {
        margin-bottom: 20px;
        .MuiInputBase-root {
            input {
                color: $base-text-color;
            }
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid #E8ECEF !important;
            }
        }
        .MuiSvgIcon-root {
            color: #E8ECEF;
        }
        .radioBox {
            &.vertical {
                display: flex;
                align-items: center;
                flex-direction: row;
                .MuiFormLabel-root {
                    padding-right: 15px;
                }
            }
            .MuiFormLabel-root {
                @include font(15px, $base-text-color !important);
            }
            .Mui-checked {
                .MuiSvgIcon-root {
                    color: $color-primary;
                }
            }
            .MuiTypography-root {
                @include font(14px, $base-text-color);
                @include roboto(400);
            }
        }
    }
    
    .btn-text {
        @include font(16px, $color-primary);
        text-transform: capitalize;
        height: 42px;
        font-family: $base-font-family;
    }
    .btn-primary {
        background-color: $color-primary;
        @include font(16px, $color-white);
        text-transform: capitalize;
        height: 40px;
        font-family: $base-font-family;
        box-shadow: none;
    }
    .btn-secondary {
        background-color: #E2E2EA !important;
        @include font(16px, #696974 !important);
        text-transform: capitalize;
        height: 40px;
        font-family: $base-font-family;
        box-shadow: none;
    }

    // Not Found
    .notFound {
        padding-top: 50px;
        width: 100%;
        text-align: center;
        img {
            max-width: 200px;
            margin-bottom: 15px;
        }
        small {
            display: block;
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            padding: 10px 15px;
            @include border-radius(10px);
            background-color: $color-primary;
            @include font(16px, $color-white);
        }
    }

    // Table
    .tableResponsive {
        box-shadow: none;
        table {
            thead {
                th {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-color: #E2E2EA;
                    @include font(13px, #696974);
                    @include roboto(700);
                }
            }
            tfoot {
                td {
                    border: 0;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @include roboto(700);
                    background-color: #f7f7f7;
                    @include font(13px, #696974);
                    border-top: 4px solid $color-white;
                }
            }
        }
        tbody {
            tr {
                &:nth-child(2n+2) {
                    td {
                        background-color: #f7f7f7;
                    }
                }
            }
            td {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: 0;
                @include font(13px, #44444F);
            }
        }
    }

    // Delete Popup
    .deleteBox {
        text-align: center;
        .icon {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 20px;
            @include border-radius(50%);
            background-color: $color-error;
            svg {
                @include font(26px, $color-white);
            }
        }
        h5 {
            @include roboto(500);
            @include font(18px, $base-heading-color);
            margin-bottom: 5px;
        }
        p {
            @include font(14px, $base-text-color);
            margin-bottom: 0;
        }
        .MuiDialogContent-root {
            padding: 20px;
        }
        .MuiDialogActions-root {
            justify-content: space-between;
            background-color: #f7f7f7;
            button {
                font-size: 14px;
            }
        }
    }

    // Multi Date Picker
    .datePicker {
        .MuiButtonBase-root {
            .MuiTypography-root {
                @include lg {
                    display: none;
                }
            }
        }
    }

    // Person Base Popup
    .MuiDialog-container {
        flex-wrap: wrap;
        overflow: auto;
    }
    .personBaseDialog {
        width: 100%;
        max-height: inherit;
        overflow: visible;
        margin: 10px;
        .MuiDialogTitle-root {
            @include font(16px, $color-white);
            background-color: $color-primary;
        }
        .MuiDialogContent-root {
            padding-top: 20px;
            padding-bottom: 0;
            .note {
                display: block;
                @include roboto(400);
                margin-bottom: 15px;
                @include font(14px, $base-text-color);
            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-left: -10px;
                margin-right: -10px;
                > .formGroup {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-left: 10px;
                    padding-right: 10px;
                    @include sm {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    &.col-4 {
                        flex: 0 0 33.33%;
                        max-width: 33.33%;
                        @include md {
                            flex: 0 0 50%;
                            max-width: 50%;
                        }
                        @include sm {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                    &.col-12 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    &__title {
                        margin-bottom: 15px;
                        .title {
                            @include font(14px, #696974);
                            position: relative;
                            padding-bottom: 10px;
                            &:after {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                content: '';
                                width: 30px;
                                height: 2px;
                                background-color: $color-primary;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .addSaleDialog {
        width: 100%;
        max-height: inherit;
        overflow: visible;
        margin: 10px;
        .MuiDialogTitle-root {
            @include font(16px, $color-white);
            background-color: $color-primary;
        }
        .MuiDialogContent-root {
            padding-top: 20px;
            padding-bottom: 0;
            .MuiTable-root {
                min-width: inherit;
            }
            table {
                @include sm {
                    display: block;
                }
                tbody {
                    tr {
                        &:nth-child(2n + 2) {
                            td {
                                @include sm {
                                    background-color: transparent;
                                }
                            }
                        }
                        td {
                            &:last-child {
                                position: relative;
                                button {
                                    position: absolute;
                                    top: calc(50% - 17px);
                                    right: 0;
                                }
                            }
                            &.sale {
                                width: 50px;
                                @include sm {
                                    width: 100%;
                                }
                            }
                            &.qty {
                                width: 120px;
                                @include sm {
                                    width: 100%;
                                }
                            }
                            &.total {
                                position: relative;
                                padding-right: 30px;
                            }

                            &.free {
                                width: 50px;
                            }
                        }
                    }
                }
                tfoot {
                    @include sm {
                        display: block;
                        width: 100%;
                        tr {
                            display: flex;
                            td {
                                flex: auto;
                                &:first-child {
                                    text-align: left;
                                }
                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
            .btn-text {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 14px;
                height: 32px;
                display: flex;
                align-items: center;
                background-color: transparent !important;
                svg {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
            .formGroup {
                margin-bottom: 0;
            }
            .recordSales {
                @include sm {
                    min-width: auto;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    thead {
                        &.top {
                            opacity: 0;
                            height: 0;
                            th {
                                height: 0;
                                display: none;
                            }
                        }
                        &.bottom {
                            @media (max-width: 575px) {
                                display: block;
                                background-color: #4b5fcb;
                                padding: 10px;
                                border-radius: 5px;
                                tr {
                                    display: flex;
                                    justify-content: space-between;
                                    th {
                                        padding: 0 !important;
                                        border-bottom: 0;
                                        font-size: 22px;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        display: block;
                    }
                }
                .mobileView {
                    @include sm {
                        display: block;
                        td {
                            display: block;
                            padding-left: 0;
                            padding-right: 0;
                            padding-bottom: 0;
                            border: 0;
                            .autoComplete {
                                .MuiAutocomplete-inputRoot {
                                    padding-right: 30px;
                                }
                            }
                            &:before {
                                display: block;
                                padding-left: 0;
                                padding-right: 10px;
                                margin-bottom: 5px;
                                @include roboto(500);
                                color: $base-text-color;
                            }
                            &.sales {
                                padding-left: 10px;
                                padding-right: 10px;
                                display: flex;
                                background-color: #3f51b5;
                                border-radius: 5px;
                                color: #ffffff;
                                padding-top: 10px;
                                padding-bottom: 5px;
                                &::before {
                                    content: 'Sales:';
                                    color: #ffffff;
                                    padding-left: 0;
                                }
                            }
                            &.item {
                                &::before {
                                    content: 'Item';
                                }
                            }
                            &.qty {
                                &::before {
                                    content: 'QTY';
                                }
                                .qty {
                                    width: 100% !important;
                                }
                            }
                            &.price {
                                &::before {
                                    content: 'Price';
                                }
                                .mobileWidth {
                                    width: 100% !important;
                                }
                            }
                            &.total {
                                display: flex;
                                justify-content: space-between;
                                padding-top: 20px;
                                padding-bottom: 15px;
                                &::before {
                                    content: 'Sub Total';
                                }
                                button {
                                    top: -258px !important;
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
            
            .mobileViewFree {
                @include sm {
                    max-width: 100% !important;
                    display: block !important;
                    thead { 
                        th {
                            height: 0;
                            display: none;
                        }
                    }
                    tbody {
                        display: block;
                    }
                }
                .mobileViewFreeRow {
                    @include sm {
                        display: block;
                        &:first-child {
                            td {
                                &.qty {
                                    padding-right: 0 !important;
                                }
                            }
                        }
                        td {
                            display: block;
                            padding-left: 0;
                            padding-right: 0;
                            padding-bottom: 0;
                            border: 0;
                            .autoComplete {
                                .MuiAutocomplete-inputRoot {
                                    padding-right: 30px;
                                }
                            }
                            &:before {
                                @include roboto(500);
                                color: $base-text-color;
                                padding-left: 10px;
                                display: block;
                                margin-bottom: 5px;
                            }
                            &.free {
                                padding-left: 10px;
                                padding-right: 10px;
                                display: flex;
                                background-color: #3f51b5;
                                border-radius: 5px;
                                color: #ffffff;
                                padding-top: 10px;
                                padding-bottom: 5px;
                                width: 100%;
                                &::before {
                                    content: 'Free:';
                                    color: #ffffff;
                                    padding-left: 0;
                                    padding-right: 10px;
                                }
                            }
                            &.item {
                                &::before {
                                    content: 'Item';
                                }
                            }
                            &.qty {
                                padding-bottom: 20px;
                                &::before {
                                    content: 'QTY';
                                }
                                .qty {
                                    width: 100% !important;
                                }
                            }
                            &:last-child {
                                padding-top: 0;
                            }
                            button {
                                top: -205px !important;
                                color: $color-white;
                            }
                        }
                    }
                }
            }
            
        }
    }


    // Date Picker Calender
    .MuiCalendarOrClockPicker-root {
        .MuiPickersDay-root {
            &.Mui-selected {
                background-color: $color-primary;
            }
            &.MuiPickersDay-today {
                border-color: $color-primary;
            }
        }
    }
    .MuiDialogActions-root {
        .MuiButton-textPrimary {
            color: $color-primary;
        }
    }
    .error {
        color: red;
        padding-top: 10px;
    }
}
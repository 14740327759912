@import 'stylesheets/common/index';

body .header {
    top: 0;
    z-index: 2;
    display: flex;
    padding: 10px 15px;
    position: sticky;
    align-items: center;
    background-color: $color-primary;
    &__menuBtn {
        margin-right: 15px;
        padding: 0;
        min-width: inherit;
        width: 42px;
        height: 42px;
        color: $color-white;
        display: none;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.1) !important;
        &:after {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            display: none;
        }
        &.active {
            &:after {
                display: block;
            }
        }
        @include md {
            display: flex;
        }
    }
    &__logo {
        width: 40px;
        height: 40px;
        overflow: hidden;
        @include border-radius(50%);
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__menu {
        padding-left: 15px;
        @include md {
            top: 72px;
            z-index: 1;
            left: -250px;
            width: 250px;
            padding: 10px;
            overflow: auto;
            position: fixed;
            @include transition(.3s);
            height: calc(100vh - 72px);
            background-color: $color-primary;
            &.show {
                left: 0;
            }
        }
        ul {
            @include liststyle(0);
            display: flex;
            margin: 0;
            @include md {
                display: block;
            }
            li {
                cursor: pointer;
                padding: 10px 15px;
                @include roboto(500);
                @include transition(.3s);
                @include border-radius(3px);
                @include font(16px, $color-white);
                &:hover,
                &.active {
                    background-color: rgba(255, 255, 255, 0.1);
                }
                &:not(:last-child) {
                    margin-right: 10px;
                    @include md {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &__user {
        margin-left: auto;
        .dropdown {
            position: relative;
            &__toggle {
                @include font(15px, $color-white);
                text-transform: capitalize;
                .name { 
                    padding-left: 10px;
                }
            }
            &__menu {
                ul {
                    @include liststyle(0);
                    li {
                        cursor: pointer;
                        padding: 10px 15px;
                        @include transition(.3s);
                        @include font(15px, $base-text-color);
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.03);
                        }
                    }
                }
            }
        }
    }
}
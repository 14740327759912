@charset "UTF-8";

//Theme Colors
$color-primary: #3f51b5;
$color-primary-light: lighten($color-primary, 5);
$color-primary-dark: darken($color-primary, 5);

$color-secondary: #00B050;
$color-secondary-light: lighten($color-secondary, 5);
$color-secondary-dark: darken($color-secondary, 5);

$color-white: #fff;
$color-black: #000;
$color-error: #f50057;
$color-blue: #0070C0;
$color-grey-dark: #2B2B2B;

//Body Attributes
$base-text-color: #404040;
$base-heading-color: #181819;
$base-font-size: 16px;
$base-line-height: 1.5;
$base-font-family: 'Roboto', sans-serif;
$base-body-bg: $color-white;

//Heading font Family
$heading-font-family: 'Roboto', sans-serif;


// Container width
$container-width: 1248px; 

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

// box shadow mixin
@mixin box-shadow($boxShadow) {
-webkit-box-shadow: $boxShadow;
    -moz-box-shadow: $boxShadow;
        box-shadow: $boxShadow;
}
// border radius mixin
@mixin border-radius($radius) {
-webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
        border-radius: $radius;
}
// transition mixin
@mixin transition($time) {
-webkit-transition: (all $time ease-in-out);
    -moz-transition: (all $time ease-in-out);
    -ms-transition: (all $time ease-in-out);
        -o-transition: (all $time ease-in-out);
        transition: (all $time ease-in-out);
}

// input appearance mixin
@mixin appearance {
-webkit-appearance: none;
    -moz-appearance: none;
            appearance: none;
}
// UL,OL Default style remove
@mixin liststyle($mb: 0) {
    list-style: none;
    padding-left: 0;
    margin: $mb;
}
// Set Font size & color
@mixin font($size, $color) {
    font-size: $size;
    color: $color;
}
// Set Roboto font family & weight
@mixin roboto($weight) {
    @if $weight == 300 {
        font-family: 'Roboto', sans-serif;
        font-weight: $weight;
    } @else if $weight == 400 {
        font-family: 'Roboto', sans-serif;
        font-weight: $weight;
    } @else if $weight == 500 {
        font-family: 'Roboto', sans-serif;
        font-weight: $weight;
    } @else if $weight == 700 {
        font-family: 'Roboto', sans-serif;
        font-weight: $weight;
    } @else {
        @error "Unknown font family #{$weight}.";
    }
}
// Set Mulish font family & weight
@mixin mulish($weight) {
    @if $weight == 400 {
        font-family: 'Mulish', sans-serif;
        font-weight: $weight;
    } @else if $weight == 700 {
        font-family: 'Mulish', sans-serif;
        font-weight: $weight;
    } @else {
        @error "Unknown font family #{$weight}.";
    }
}

// # Max Media Query Breakdowns
$xs: 380px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
//Desktop Media Query Breakdowns
$desktop-1366: 1366px;
$desktop-1400: 1400px;
$desktop-1500: 1500px;
$desktop-1600: 1600px;
$desktop-1700: 1700px;
$desktop-1800: 1800px;
$desktop-1920: 1920px;

// extra Small devices
@mixin xs {
	@media (max-width: #{$xs}) {
		@content;
	}
}

// Small devices
@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
}
 
// Medium devices
@mixin md {
    @media (max-width: #{$md}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
}
 
// Extra large devices
@mixin xl {
    @media (max-width: #{$xl}) {
        @content;
    }
}

// Desktop 1366px
@mixin desktop-1366 {
    @media (max-width: #{$desktop-1366}) {
        @content;
    }
}

// Desktop 1400px
@mixin desktop-1400 {
    @media (max-width: #{$desktop-1400}) {
        @content;
    }
}

// Desktop 1500px
@mixin desktop-1500 {
    @media (max-width: #{$desktop-1500}) {
        @content;
    }
}

// Desktop 1600
@mixin desktop-1600 {
    @media (max-width: #{$desktop-1600}) {
        @content;
    }
}

// Desktop 1700px
@mixin desktop-1700 {
    @media (max-width: #{$desktop-1700}) {
        @content;
    }
}

// Desktop 1800px
@mixin desktop-1800 {
    @media (max-width: #{$desktop-1800}) {
        @content;
    }
}

// Desktop 1920px
@mixin desktop-1920 {
    @media (max-width: #{$desktop-1920}) {
        @content;
    }
}

//Custom Max Width Media Query
@mixin custom-max($width) {
    @media (max-width: #{$width}) {
        @content;
    }
}
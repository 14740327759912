@import 'stylesheets/common/index';

.register {
    padding: 10px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../../assets/images/login-bg.jpg);
    &__cover {
        width: 100%;
        margin: 0 auto;
        max-width: 600px;
        padding: 35px 15px 15px;
        @include border-radius(10px);
        background-color: $color-white;
        @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
    }
    .recaptcha-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0rem;
      }
    img {
        width: 100%;
        max-width: 70px;
        margin: 0 auto 20px;
        display: block;
    }
    h3 {
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        @include font(20px, $color-primary);
    }
    .formGroup {
        margin-bottom: 0;
    }
    .btn-primary {
        width: 100%;
    }
    small {
        text-align: center;
        margin-top: 30px;
        display: block;
        @include font(12px, #778CA2);
        b {
            cursor: pointer;
            &:hover {
                color: $color-primary;
            }
        }
    }
}